import React from 'react'

const Dummy = (props) => {
  return (
    <div style={{width:"100%",height:`${props.height}`,backgroundColor:"white"}}>
      
    </div>
  )
}

export default Dummy
