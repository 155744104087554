import React from 'react'
import Heading from '../Heading'
const Schedule = () => {
  return (
    <div className='mt-5'>
    {<Heading>schedule</Heading>}
    <div style={{width:"100%",height:"440px",display:'flex',justifyContent:'center'}}>
      <h1>Will be updated soon..</h1>
    </div>
   </div>
  )
}

export default Schedule
